// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexBookACall from "../../../common/locations-index-book-a-call/LocationsIndexBookACall.res.js";
import * as LocationsIndexBookACallSectionScss from "./LocationsIndexBookACallSection.scss";

var css = LocationsIndexBookACallSectionScss;

function LocationsIndexBookACallSection(props) {
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.heading,
                      children: "Looking for the Best Offer? Our Experts are Always Ready to Help!"
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "XS",
                      color: "Teal",
                      onClick: (function (param) {
                          setModalOpen(function (param) {
                                return true;
                              });
                        }),
                      children: "Book a Call"
                    }),
                match[0] ? JsxRuntime.jsx(LocationsIndexBookACall.make, {
                        onClose: (function () {
                            setModalOpen(function (param) {
                                  return false;
                                });
                          })
                      }) : null
              ],
              className: css.wrapper
            });
}

var make = LocationsIndexBookACallSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
