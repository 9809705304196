// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../../styleguide/components/Heading/H3.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexTopProvidersScss from "./LocationsIndexTopProviders.scss";

var css = LocationsIndexTopProvidersScss;

function LocationsIndexTopProviders(props) {
  var topProviders = props.topProviders;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs(H3.make, {
                      className: css.title,
                      children: [
                        JsxRuntime.jsx("span", {
                              children: props.localityName.toUpperCase(),
                              className: css.localityName
                            }),
                        JsxRuntime.jsx("br", {}),
                        "Top " + String(topProviders.length) + "\n        Colocation Providers"
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.map(topProviders, (function (provider) {
                              var match = provider.totalLocations;
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("img", {
                                                        className: css.providerImage,
                                                        alt: provider.name,
                                                        src: provider.logo
                                                      }),
                                                  className: css.providerLogo
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx("a", {
                                                          children: provider.name,
                                                          className: css.providerName,
                                                          href: Routes_Provider.show(provider.slug)
                                                        }),
                                                    JsxRuntime.jsx("span", {
                                                          children: match !== 1 ? String(provider.totalLocations) + " locations" : "1 location",
                                                          className: css.totalLocations
                                                        })
                                                  ],
                                                  className: css.providerData
                                                })
                                          ],
                                          className: css.provider
                                        }, provider.slug);
                            })),
                      className: css.providers
                    })
              ],
              className: css.providersSidebar
            });
}

var make = LocationsIndexTopProviders;

export {
  css ,
  make ,
}
/* css Not a pure module */
